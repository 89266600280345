import { FC } from 'react'
import { SVGProps } from '../Icon'

const SimbiozLogoGradient: FC<SVGProps> = ({ className, id }) => (
    <svg
        className={className}
        width="34"
        height="20"
        viewBox="0 0 34 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.243 20C7.3413 20 4.93542 19.045 3.02541 17.135C1.1154 15.2066 0.1604 12.8283 0.1604 10C0.1604 7.17172 1.1154 4.80257 3.02541 2.89256C4.93542 0.964188 7.3413 0 10.243 0H13.5488H15.4741V3.0854H13.5488H10.243C8.18611 3.0854 6.51485 3.73738 5.22927 5.04132C3.96205 6.34527 3.32844 7.99816 3.32844 10C3.32844 12.0018 3.96205 13.6547 5.22927 14.9587C6.51485 16.2626 8.18611 16.9146 10.243 16.9146H13.5488C14.0165 16.9146 14.1172 16.91 14.1959 16.9065H14.1959C14.3107 16.9013 14.3785 16.8982 15.4741 16.9146V20L13.5488 20H10.243ZM23.757 20C26.6587 20 29.0646 19.045 30.9746 17.135C32.8846 15.2066 33.8396 12.8283 33.8396 10C33.8396 7.17172 32.8846 4.80257 30.9746 2.89256C29.0646 0.964188 26.6587 0 23.757 0H20.4512H18.5259V3.0854H20.4512H23.757C25.8139 3.0854 27.4851 3.73738 28.7707 5.04132C30.0379 6.34527 30.6716 7.99816 30.6716 10C30.6716 12.0018 30.0379 13.6547 28.7707 14.9587C27.4851 16.2626 25.8139 16.9146 23.757 16.9146H20.4512C19.9835 16.9146 19.8828 16.91 19.8042 16.9065H19.8041C19.6893 16.9013 19.6215 16.8982 18.5259 16.9146V20L20.4512 20H23.757ZM23.1172 8.0349H10.8736V11.861H23.1172V8.0349Z"
            fill={`url(#${id})`}
        />
        <defs>
            <linearGradient
                id={id}
                x1="33.8396"
                y1="-1.05859e-05"
                x2="-1.41895"
                y2="3.34308"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
        </defs>
    </svg>
)

export default SimbiozLogoGradient
